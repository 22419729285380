import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { Seo } from "../components/index"
import { Container, SEOTitle, Text, Column, Button } from "../theme"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Container>
      <Column>
        <SEOTitle>Whoops! Page Not Found</SEOTitle>
        <Text>Please Return to the Home Page of CheshTech Digital Agency</Text>
        <Link to="/">
          <Button margin="10px 0 0 0">Go Home</Button>
        </Link>
      </Column>
    </Container>
  </Layout>
)

export default NotFoundPage
